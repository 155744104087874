import React from 'react';
import { useHistory } from 'react-router-dom';

interface IGOBackLinkProps {
  className?: string;
  url?: string | null;
  onClick?: () => void;
}

export default function GoBackLink(props: IGOBackLinkProps) {
  const nav = useHistory();

  function onClick(ev: React.SyntheticEvent) {
    ev.preventDefault();
    if (props.url !== null) {
      props.url ? nav.push(props.url) : nav.push(process.env.PUBLIC_URL);
    }
    props.onClick && props.onClick();
  }

  return <a href="#root" className={props.className} onClick={onClick} />;
}
