import { Action, useParameterizedQuery } from 'react-fetching-library';
import qs from 'qs';
import { IUpdateLiveEvent, ReCreateLiveEventType } from './events.types';

export function getActiveEvents(params: object): Action {
  const search = qs.stringify(params, { addQueryPrefix: true });

  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/actives${search}`,
  };
}

export function getEvents(): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events`,
  };
}

export function getAllEvents(): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/all_events`,
  };
}

export function getEventsByParams(params: object): Action {
  const search = qs.stringify(params, { addQueryPrefix: true });

  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events${search}`,
  };
}

export interface ISlugParams {
  slug: string;
}

export function getEventAction(slug: string): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/${slug}`,
  };
}

export function createEventAction({
  event,
}: {
  event: IUpdateLiveEvent;
}): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events`,
    body: { ...event },
  };
}

export function updateEventAction({
  slug,
  event,
}: {
  slug: string;
  event: IUpdateLiveEvent;
}): Action {
  return {
    method: 'PATCH',
    endpoint: `/api/admin/content/live/events/${slug}`,
    body: { ...event },
  };
}

export function reCreateEventAction({
  slug,
  params,
}: {
  slug: string;
  params: ReCreateLiveEventType;
}): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${slug}/recreate`,
    body: { ...params },
  };
}

export function updateEventStatusAction({
  slug,
  status,
}: {
  slug: string;
  status: string;
}): Action {
  return {
    method: 'PATCH',
    endpoint: `/api/admin/content/live/events/${slug}/status`,
    body: { status: status },
  };
}

export function sendPushNotificationAction(slug: string): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${slug}/push_notifications`,
  };
}

export function getPastEvents(params: object): Action {
  const search = qs.stringify(params, {
    addQueryPrefix: true,
    encode: false,
    arrayFormat: 'brackets',
  });

  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/pasts${search}`,
  };
}

export function getLanguageData(): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/language_data`,
  };
}

export function getTranscodersData(): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/transcoders`,
  };
}

export function getEventBookingsAction(params: ISlugParams): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/${params.slug}/bookings`,
  };
}

export function getEventFollowingAction(params: ISlugParams): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/${params.slug}/subscriptions`,
  };
}

export function getEventTargetsAction(params: ISlugParams): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/${params.slug}/targets`,
  };
}

export function getEventTargetsPastAction(params: ISlugParams): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/${params.slug}/targets/pasts`,
  };
}

export function getTargetsStatisticsAction(params: ISlugParams): Action {
  return {
    method: 'GET',
    endpoint: `/api/admin/content/live/events/${params.slug}/targets/stream_statistics`,
  };
}

export interface INotificationParams {
  slug: string;
  notification: string;
}

export function sendCustomNotificationsAction(
  params: INotificationParams
): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${params.slug}/custom_notification`,
    body: {
      notification: {
        message: params.notification,
      },
    },
  };
}

export function sendNotifyClientDeskAction(params: ISlugParams): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${params.slug}/links_client_desk_notifications`,
  };
}

export interface IReportDelayParams {
  slug: string;
  time: number;
}

export function reportLiveEventDelayAction(params: IReportDelayParams): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${params.slug}/delay`,
    body: {
      time: params.time,
    },
  };
}

export function liveEventBookingOneOff(params: {
  slug: string;
  data: {
    language_id: number;
    company_id: number;
  };
}): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${params.slug}/bookings`,
    body: {
      ...params.data,
    },
  };
}

export function useEventBookingOneOff() {
  return useParameterizedQuery(liveEventBookingOneOff);
}

export function sendTranslationNotifications(params: {
  slug: string;
  type: string;
  locale: string;
}): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${params.slug}/translation_change_notifications`,
    body: {
      send_to: params.type,
      locale: params.locale,
    },
  };
}

export function useSendTranslationNotifications() {
  return useParameterizedQuery(sendTranslationNotifications);
}

export function sendLinksNotifyClientDeskAction(params: ISlugParams): Action {
  return {
    method: 'POST',
    endpoint: `/api/admin/content/live/events/${params.slug}/links_client_desk_notifications`,
  };
}

export interface ISyncLiveEventParams {
  slug: string;
}

export function syncLiveEventAction(params: ISyncLiveEventParams): Action {
  return {
    endpoint: `/api/admin/content/live/events/${params.slug}/synchronizations`,
    method: 'POST',
  };
}

export function unsyncLiveEventAction(params: ISyncLiveEventParams): Action {
  return {
    endpoint: `/api/admin/content/live/events/${params.slug}/synchronizations`,
    method: 'DELETE',
  };
}
