import qs from 'qs';
import {
  Action,
  createCache,
  createClient,
  QueryResponse,
  RequestInterceptor,
} from 'react-fetching-library';
import config from 'config';

const cache = createCache<QueryResponse<any>>(
  (action: Action) => {
    return action.method === 'GET';
  },
  (response: QueryResponse<any> & { timestamp: number }) => {
    return new Date().getTime() - response.timestamp < 10000;
  }
);

const requestHostInterceptor: (host: string) => RequestInterceptor =
  (host) => () => async (action) => {
    return {
      credentials: 'include',
      ...action,
      endpoint: `${host}${action.endpoint}${
        action.config?.search
          ? qs.stringify(action.config.search, { addQueryPrefix: true })
          : ''
      }`,
    };
  };

// const responsePayloadInterceptor = () => async (
//     action: Action,
//     response: any
// ) => {
//     const { extractContent = (p: any) => p } = action.config || {};
//     let payload = extractContent(response.payload);
//     return {
//         ...response,
//         payload,
//     };
// };

export default createClient({
  requestInterceptors: [requestHostInterceptor(config.API_HOST)],
  responseInterceptors: [],
  // cacheProvider: cache,
});
