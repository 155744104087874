import Cookies from 'js-cookie';

export type RedashKey =
  | 'REDASH_STATISTIC_24_hours'
  | 'REDASH_STATISTIC_7_days'
  | 'REDASH_STATISTIC_30_days'
  | 'REDASH_STATISTIC_90_days'
  | 'REDASH_STATISTIC_1_year';

interface Config {
  API_HOST: string;
  LIVE_HOST: string;
  VIDEO_UPLOADER_HOST: string;
  REDASH_STATISTIC_24_hours: string;
  REDASH_STATISTIC_7_days: string;
  REDASH_STATISTIC_30_days: string;
  REDASH_STATISTIC_90_days: string;
  REDASH_STATISTIC_1_year: string;
  PROJECT_LOCALE: string;
  MODE: string;
  STORAGE_HOST: string;
  MAIN_HOST: string;
  FACEBOOK: string;
  INSTAGRAM: string;
  TWITTER: string;
  YOUTUBE: string;
  WEIBO: string;
  BRAND_NAME: string;
  SWITCH: boolean;
  isProd: boolean;
}

const mode = checkEnvVariable('REACT_APP_BUILD_MODE');

const PROJECT_LOCALE = Cookies.get('language_code') || 'en';

function checkEnvVariable(name: string): string {
  const value = process.env[name];

  if (!value) {
    throw new Error(`Environment variable ${name} is not set.`);
  }
  return value;
}

const config: Config = {
  API_HOST: checkEnvVariable(`REACT_APP_${mode}_API_HOST`),
  LIVE_HOST: checkEnvVariable(`REACT_APP_${mode}_LIVE_HOST`),
  VIDEO_UPLOADER_HOST: checkEnvVariable(
    `REACT_APP_${mode}_VIDEO_UPLOADER_HOST`
  ),
  REDASH_STATISTIC_24_hours: checkEnvVariable(
    'REACT_APP_REDASH_STATISTIC_24_hours'
  ),
  REDASH_STATISTIC_7_days: checkEnvVariable(
    'REACT_APP_REDASH_STATISTIC_7_days'
  ),
  REDASH_STATISTIC_30_days: checkEnvVariable(
    'REACT_APP_REDASH_STATISTIC_30_days'
  ),
  REDASH_STATISTIC_90_days: checkEnvVariable(
    'REACT_APP_REDASH_STATISTIC_90_days'
  ),
  REDASH_STATISTIC_1_year: checkEnvVariable(
    'REACT_APP_REDASH_STATISTIC_1_year'
  ),
  STORAGE_HOST: checkEnvVariable('REACT_APP_STORAGE_HOST'),
  MAIN_HOST: checkEnvVariable('REACT_APP_MAIN_HOST'),
  FACEBOOK: checkEnvVariable('REACT_APP_FACEBOOK'),
  INSTAGRAM: checkEnvVariable('REACT_APP_INSTAGRAM'),
  TWITTER: checkEnvVariable('REACT_APP_TWITTER'),
  YOUTUBE: checkEnvVariable('REACT_APP_YOUTUBE'),
  WEIBO: checkEnvVariable('REACT_APP_WEIBO'),
  BRAND_NAME: checkEnvVariable('REACT_APP_BRAND_NAME'),
  PROJECT_LOCALE: PROJECT_LOCALE,
  MODE: mode,
  SWITCH: checkEnvVariable('REACT_APP_SWITCH') === 'true',
  isProd: ['YANDEX_PROD', 'DARPO'].includes(mode),
};

export default config;
