import config from 'config';

export function getUrlFromPath(path: string, params: any = {}): string {
  return path
    .replace(/:([\w_-]+)/g, (...args: any[]) => {
      return params && params[args[1]]
        ? String(params[args[1]])
        : String(args[0]);
    })
    .replace(/\/\//g, '/')
    .replace(/\(.*:.*\)\?/g, '')
    .replace(/[()?]/g, '');
}

export const fillEmptyStr = (
  ...list: (undefined | null | string)[]
): string => {
  const str = list.reduce((prev, curr) => {
    return curr ? `${prev} ${curr}` : prev;
  }, '') as string;

  if (!str) return 'N/A';

  return str.length >= 20 ? `${str.trim().substring(0, 20)}...` : str;
};

export const countItemsOnPage = (
  name: string,
  currentPage: number,
  per_page: number,
  isShowMore: boolean,
  countItemsOnPage: number,
  countAllItems: number
): string => {
  const endNumber = currentPage * per_page;
  const startNumber_temp = endNumber - countItemsOnPage;
  const startNumber_ifShowMore = startNumber_temp === 0 ? 1 : startNumber_temp;
  const startNumber =
    currentPage === 1
      ? 1
      : isShowMore
      ? startNumber_ifShowMore
      : (currentPage - 1) * per_page;

  return `${countAllItems > 0 ? `${startNumber} - ${endNumber}` : 0} of ${
    countAllItems || 'N/A'
  } ${name}`;
};

export const getCurrencySymbol = (juridicDirection?: string | null): string => {
  switch (juridicDirection) {
    case 'video':
      return '₽';
    case 'tv':
      return '€';
    default:
      return '€';
  }
};

export const getFixedLiveHost = (isRu: boolean) =>
  isRu
    ? config.LIVE_HOST.replace(/^(\w+:\/\/[^/]+\.)ruptly\.tv(.*)$/i, '$1ruptly.video$2') // change to ".ruptly.video"
    : config.LIVE_HOST;
