import { useMemo } from 'react';
import qs, { ParsedQs } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

type TSearchParams<T extends Partial<ParsedQs> = {}> = ParsedQs & T;

export const useSearchParams = <T extends Partial<ParsedQs> = {}>(): [
  TSearchParams<T>,
  (params: TSearchParams<T>) => void,
] => {
  const location = useLocation();
  const history = useHistory();

  const params = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location.search]
  ) as TSearchParams<T>;

  const setParams = (newParams: TSearchParams<T>) => {
    const search = qs.stringify(newParams || {}, {
      addQueryPrefix: true,
      encode: false,
      format: 'RFC1738',
    });
    history.replace(`${location.pathname}${search}`);
  };

  return [params, setParams];
};
