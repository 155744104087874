import React, { useCallback, useState, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import { useCurrentUser } from 'api/current_user';
import { useLocales } from 'api/locales';
import { NotifyAppContext } from 'components/notifications';
import { NotifyAppType } from 'components/types';
import {
  AlertsHistoryRoute,
  AlertsRoute,
  BannersHistoryRoute,
  BannersRoute,
  RootADsRoute,
} from 'routes/ads';
import {
  FtpVideosRoute,
  LiveEventsRoute,
  LiveEventTagsRoute,
  LiveEventTranslationsRoute,
  LiveEventViewRoute,
  RootContentRoute,
  TagsOnDashboardRoute,
  TagsRoute,
  TagsHistoryRoute,
  TagsOnDashboardHistoryRoute,
  VideosRoute,
  VideosViewDownloadsRoute,
  VideosViewRoute,
  VideosViewTagsRoute,
  VideosViewTranslationsRoute,
} from 'routes/content';
import {
  CheckEmailRoute,
  CustomerRoute,
  ResetPasswordRoute,
  RootUsersRoute,
  SignInRoute,
  UsersRoute,
  UsersRouteTypes,
} from 'routes/users';
import Header from './components/layout/header';
import { RootSettingsRoute } from 'routes/settings';
import { ToSRootRoute } from 'routes/tos';
import { ToURootRoute } from 'routes/tou';
import { RootStatisticsRoute } from 'routes/statistics';
import { NewLiveEventProvider } from 'pages/content/live-events/lives-view-item/edit-modals/new-live-context';
import Footer from 'components/layout/footer';
import Loader from 'components/loader';
import { PlanningSectionRoute } from './routes/planning-section';
import { RegionsProvider } from './api/admin/content/regions';
import { CountriesProvider } from './api/admin/content/countries';
import { AllCountriesProvider } from 'api/countries/all-countries';
import CombineProviders from 'hooks/combine-providers';
import { NewslettersRoute } from './routes/newsletters';
import config from 'config';

if (config.SWITCH) {
  require('./assets_v/css/main.css');
  require('./assets_v/css/sub.css');
} else {
  require('./assets/css/main.css');
  require('./assets/css/sub.css');
}

export default function App() {
  const [notifyList, updateNotifyList] = useState<NotifyAppType[]>([]);
  const current_user = useCurrentUser();
  const { mainLocale } = useLocales();

  const isAuthorized =
    !current_user.loading &&
    !current_user.error &&
    current_user.payload?.current_user?.id;
  const isAdmin = current_user.payload?.current_user?.user_role === 'admin';
  window.adminType = current_user.payload?.current_user?.admin_group || '';
  window.isAdmin = isAdmin;

  const showNotifyHandle = useCallback((config: Omit<NotifyAppType, 'id'>) => {
    updateNotifyList((prev) => {
      return [...prev, { ...config, id: Date.now() }];
    });
  }, []);

  const closeNotifyHandle = useCallback((id: number) => {
    updateNotifyList((prev) => {
      const index = prev.findIndex((notify) => notify.id === id);
      prev.splice(index, 1);
      return [...prev];
    });
  }, []);

  if (current_user.payload === undefined) {
    return <Loader style={{ display: 'block' }} />;
  }

  return (
    <Router basename="/cms">
      <div className="page page--fs" style={{ minHeight: '100vh' }}>
        <NotifyAppContext.Provider
          value={{
            list: notifyList,
            showNotifyHandle,
            closeNotifyHandle,
          }}
        >
          <Header />
          <Suspense fallback={<Loader />}>
            {isAuthorized && isAdmin ? (
              <CombineProviders
                providers={[RegionsProvider, CountriesProvider, AllCountriesProvider]}
              >
                <Switch>
                  <Redirect exact from={'/'} to={VideosRoute.getLink()} />
                  <Redirect exact from={'/signin'} to={VideosRoute.getLink()} />
                  <Redirect
                    exact
                    from={RootContentRoute.getLink()}
                    to={VideosRoute.getLink()}
                  />
                  <Redirect
                    exact
                    from={RootUsersRoute.getLink()}
                    to={UsersRoute.getLink({ type: UsersRouteTypes.ACTIVE })}
                  />
                  <VideosRoute />
                  <FtpVideosRoute />
                  <VideosViewRoute />
                  <VideosViewDownloadsRoute />
                  <VideosViewTranslationsRoute />
                  <VideosViewTagsRoute />
                  <LiveEventsRoute />
                  <TagsHistoryRoute />
                  <TagsOnDashboardHistoryRoute />
                  <TagsRoute />
                  <TagsOnDashboardRoute />
                  <CustomerRoute />
                  <UsersRoute />
                  <Redirect
                    exact
                    from={RootADsRoute.getLink()}
                    to={BannersRoute.getLink({
                      locale: mainLocale,
                    })}
                  />
                  <BannersRoute />
                  <AlertsRoute />
                  <AlertsHistoryRoute />
                  <BannersHistoryRoute />
                  <RootSettingsRoute />
                  <RootStatisticsRoute />
                  {config.SWITCH ? <ToURootRoute /> : <ToSRootRoute />}
                  <PlanningSectionRoute />
                  {!config.SWITCH && <NewslettersRoute />}
                  <NewLiveEventProvider>
                    <LiveEventViewRoute />
                    <LiveEventTranslationsRoute />
                    <LiveEventTagsRoute />
                  </NewLiveEventProvider>
                </Switch>
              </CombineProviders>
            ) : (
              <>
                <Redirect exact to={SignInRoute.getLink()} />
                <SignInRoute />
                <ResetPasswordRoute />
                <CheckEmailRoute />
              </>
            )}
          </Suspense>
        </NotifyAppContext.Provider>
      </div>
      <Footer />
    </Router>
  );
}
