import { Action } from 'react-fetching-library';
import createQueryContext from 'react-fetching-library-context';

interface Timezones {
  time_zones: string[];
}

function getTimezonesAction(): Action {
  return {
    method: 'GET',
    endpoint: `/api/time_zones`,
  };
}

export const {
  useQueryContext: useTimezonesContext,
  Provider: TimezonesProvider,
} = createQueryContext<Timezones>(getTimezonesAction);
