import { IOption } from 'components/components';
import { useMemo } from 'react';
import { Action } from 'react-fetching-library';
import createQueryContext from 'react-fetching-library-context';
import config from 'config';

export function getLocalesAction(): Action {
  return {
    endpoint: '/api/locales',
    method: 'GET',
  };
}

export interface ILocales {
  code: string;
  name: string;
}

export const { useQueryContext: useLocalesContext, Provider: LocalesProvider } =
  createQueryContext<ILocales[]>(getLocalesAction);

export function useLocales() {
  const { payload: locales = [] } = useLocalesContext();

  return useMemo(() => {
    let codes: string[] = [];
    let names: string[] = [];
    let mainLocale = locales[0] ? locales[0].code : config.PROJECT_LOCALE;
    let localeOptions: IOption[] = [];

    locales.forEach(({ code, name }) => {
      codes.push(code);
      names.push(name);
      localeOptions.push({
        label: name,
        value: code,
      });

      if (code === config.PROJECT_LOCALE) {
        mainLocale = code;
      }
    });

    return {
      mainLocale,
      all: locales,
      codes,
      names,
      localeOptions,
    };
  }, [locales]);
}
