import route from 'hooks/route';
import { clientDeskConfig } from '../constants/accesses';
import React from 'react';

export enum UsersRouteTypes {
  NEW = 'new',
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  UNCONFIRMED = 'unconfirmed',
  ADMINS = 'admins',
}

export type UsersRouteProps = {
  type: UsersRouteTypes;
  id?: string;
};

export const SignInRoute = route({
  path: '/signin',
  component: React.lazy(() => import('pages/signin/index')),
});

export const ResetPasswordRoute = route({
  path: '/restore-password',
  component: React.lazy(() => import('pages/signin/ResetPasswordPage')),
});

export const CheckEmailRoute = route({
  path: '/check-email',
  component: React.lazy(() => import('pages/signin/CheckEmail')),
});

export const RootUsersRoute = route({
  path: '/users',
  component: React.lazy(() => import('pages/users/main')),
  accessConfig: clientDeskConfig,
});

export const UsersRoute = route<UsersRouteProps>({
  path: '/users/:type',
  component: React.lazy(() => import('pages/users/main')),
  accessConfig: clientDeskConfig,
});

export const CustomerProfileRoute = route<UsersRouteProps>({
  path: '/users/:type/:id/profile',
  component: React.lazy(() => import('pages/users/customer/profile')),
  accessConfig: clientDeskConfig,
});

export const CustomerBullingRoute = route<UsersRouteProps>({
  path: '/users/:type/:id/billing',
  component: React.lazy(() => import('pages/users/customer/billing')),
  accessConfig: clientDeskConfig,
});

export const CustomerPlansRoute = route<UsersRouteProps>({
  path: '/users/:type/:id/plans',
  component: React.lazy(() => import('pages/users/customer/plans')),
  accessConfig: clientDeskConfig,
});

export const CustomerDownloadsRoute = route<UsersRouteProps>({
  path: '/users/:type/:id/downloads',
  component: React.lazy(() => import('pages/users/customer/downloads')),
  accessConfig: clientDeskConfig,
});

export const CustomerMembersRoute = route<UsersRouteProps>({
  path: '/users/:type/:id/members',
  component: React.lazy(() => import('pages/users/customer/members')),
  accessConfig: clientDeskConfig,
});

export const CustomerPurchasesRoute = route<UsersRouteProps>({
  path: '/users/:type/:id/purchases',
  component: React.lazy(() => import('pages/users/customer/purchases')),
  accessConfig: clientDeskConfig,
});

export const CustomerRoute = route<UsersRouteProps>({
  path: '/users/:type/:id',
  component: React.lazy(() => import('pages/users/customer')),
});
