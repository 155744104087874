import { Action } from 'react-fetching-library';

export function getCurrentUserAction(): Action {
  return {
    method: 'GET',
    endpoint: '/api/current_user.json',
  };
}

export type SignInParams = {
  user: {
    email: string;
    password: string;
  };
};

export function signInAction(params: SignInParams): Action {
  return {
    method: 'POST',
    endpoint: '/users/sign_in.json',
    body: params,
  };
}

export function adminVerifyAction(token: string): Action {
  return {
    method: 'POST',
    endpoint: '/api/admin/users/admins/token_verifications',
    body: {
      token: token,
    },
  };
}

export function signOutAction(): Action {
  return {
    method: 'DELETE',
    endpoint: '/users/sign_out',
  };
}

export const resetPassword = (email: string): Action => ({
  method: 'POST',
  endpoint: '/api/users/password_instructions',
  body: {
    email: email,
  },
});
