import { IUpdateLiveEvent } from 'api/admin/content/live/events/events.types';

export const initLive: IUpdateLiveEvent | null = {
  title: '',
  slug: '',
  text_slug: '',
  start_at: '',
  duration: 0,
  durationTime: { hh: 0, mm: 0 },
  languages: [],
  limit_type: 'regular_content',
  free_access: false,
  visible: true,
  clients_only: false,
  cutting_available: true,
  front_page_ban: false,
  image: {
    v273x154: { url: '' },
  },
  landing_and_search_only: false,
  image_data: '',
  image_type: 'default',
  status: 'new',
  quality: 'streaming',
  // format: 'flat',
  stream_to: {
    custom_rtmp: {
      hd: '0',
      full_hd: '1',
    },
    facebook: {
      api: '1',
      base: '1',
    },
    hls_full_hd: '0',
    odnoklassniki: '1',
    periscope: '1',
    srt: '0',
    tvu: '0',
    vkontakte: '1',
    youtube: '1',
  },
  tags: [],
  tag_ids: [],
  meta_tag: null,
  meta_tag_attributes: null,
  // original_image: "",
  // original_image_data: "",
  restriction_dictionary_value_ids: [],
  // booked_count: 0,
  // followed_count: 0,
  // streaming_now: 0,
  region_id: -1,
  region_name: '',
  country_id: -1,
  country_name: '',
  transcoder_id: -1,
  start_at_not_finalized: false,
};
