import React from 'react';

import route from 'hooks/route';
import { superAdminConfig } from 'constants/accesses';

export const ToURootRoute = route({
  path: '/tou',
  component: React.lazy(() => import('pages/tou/index')),
  accessConfig: superAdminConfig,
});

export const ToUFormRoute = route<{ locale: string }>({
  path: '/tou/:locale',
  component: React.lazy(() => import('pages/tos/tos-form')),
  accessConfig: superAdminConfig,
  exact: true,
});
