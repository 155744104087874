import createQueryContext from 'react-fetching-library-context';
import {
  adminVerifyAction,
  getCurrentUserAction,
  resetPassword,
  signInAction,
  SignInParams,
  signOutAction,
} from './current_user';
import { ICurrentUser } from './current_user.types';
import { useMutation } from 'react-fetching-library';

const { useQueryContext: useCurrentUser, Provider: CurrentUserProvider } =
  createQueryContext<ICurrentUser>(getCurrentUserAction);

export { useCurrentUser, CurrentUserProvider };

export function useSignIn() {
  const signIn = useMutation(signInAction);

  return async function (params: SignInParams) {
    const result = await signIn.mutate(params);
    return result;
  };
}

export function useAdminVerify() {
  const verify = useMutation(adminVerifyAction);
  const currentUser = useCurrentUser();

  return async function (token: string) {
    await verify.mutate(token);
    return await currentUser.query(null);
  };
}

export function useSignOut() {
  const signOut = useMutation(signOutAction);
  const currentUser = useCurrentUser();

  return async function () {
    await signOut.mutate({});
    return await currentUser.query(null);
  };
}

export function useResetPassword() {
  const reset = useMutation(resetPassword);

  return async function (email: string) {
    return await reset.mutate(email);
  };
}
